import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import Logo from "../../../static/img/branding/givhub-logo-marine.svg";
import "../../../static/styles/comps/global-header.scss";

export default props => {
  let [searchTerm, setTerm] = useState("");
  return (
    <nav className="global-header">
      <div className="logo">
        <img alt="GivHub Logo" width="auto" src={Logo} />
      </div>
      <div className="searchWrapper">
        <form>
          <input
            className="search-input"
            type="text"
            value={searchTerm}
            onChange={e => {
              setTerm(e.currentTarget.value);
            }}
            placeholder="Type organization or school name here"
          />
          <button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              navigate("/search/#" + searchTerm);
            }}
            className="search-btn"
          >
            Search
          </button>
        </form>
      </div>
      <div className="menu">
        <Link to="/login/">Sign In</Link>
        <Link to="/signup/">Create Account</Link>
        <Link to="/search/">Add Organization</Link>
      </div>
    </nav>
  );
};
