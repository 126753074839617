import React from "react";
import HomePage from "../components/layout/Home";
import GlobalHeader from "../components/common/GlobalHeader";

export default props => {
  return (
    <HomePage>
      <GlobalHeader />
    </HomePage>
  );
};
